import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import GoogleMapReact from "google-map-react"
import Tabs, { TabPane } from "rc-tabs"
import HeroImg from "../../components/HeroImg/HeroImg"
import MainLayout from "../../layouts/MainLayout"
import mapStyles from "../../map-style.json"
import { GOOGLE_API_KEY } from "../../constants"
import "./contact.scss"

const Marker = () => {
  return (
    <div className="anima">
      <img src="/img/map-location.svg" alt="location" />
    </div>
  )
}

const initialFormData = {
  name: "",
  email: "",
  title: "",
  message: "",
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const createMapOptions = map => ({
  styles: mapStyles,
})

const locations = {
  bg: { lat: 44.812062, lng: 20.455687 },
  gm: { lat: 44.0551537, lng: 20.4813724 },
}

const Contact = () => {
  const { t } = useTranslation()
  const [mapCenter, setMapCenter] = useState(locations.bg)
  const [formData, setFormData] = useState({ ...initialFormData })
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const onChange = a => {
    setMapCenter(locations[a])
  }

  const onInputChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setIsFormSubmitted(false)
  }

  const isFormValid = () => {
    return (
      !!formData.name &&
      !!formData.email &&
      !!formData.message &&
      !!formData.title
    )
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (isFormValid() === false) return
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-form", ...formData }),
    }).then(onSuccess)
  }

  const onSuccess = () => {
    setFormData({ ...initialFormData })
    setIsFormSubmitted(true)
  }

  return (
    <MainLayout metaTitle={t("contact:metaTitle")}>
      <>
        <HeroImg
          customClass="contact-hero"
          subtitle={t("contact:heroSubtitle")}
          title={t("contact:heroTitle")}
          backgroundImage="bg/contact-hero.jpg"
        ></HeroImg>

        <div className="contact">
          <div className="contact-section-one">
            <p>{t("contact:sectionOneTextOne")} </p>
            <p>{t("contact:sectionOneTextTwo")} </p>
            <p>{t("contact:sectionOneTextTwo/two")}</p>
          </div>

          <div className="form">
            <h4>{t("contact:formTitle")}</h4>
            <form
              onSubmit={handleSubmit}
              data-netlify="true"
              name="contact-form"
            >
              <div className="position-input">
                <div className="input-container">
                  <label htmlFor="name">{t("contact:name")}</label>
                  <input
                    type="text"
                    name="name"
                    onKeyPress={e => {
                      e.key === "Enter" && e.preventDefault()
                    }}
                    value={formData.name}
                    onChange={onInputChange}
                  ></input>
                </div>
                <div className="input-container">
                  <label htmlFor="e-mail">{t("contact:email")}</label>
                  <input
                    type="text"
                    name="email"
                    onKeyPress={e => {
                      e.key === "Enter" && e.preventDefault()
                    }}
                    value={formData.email}
                    onChange={onInputChange}
                  ></input>
                </div>
              </div>
              <label htmlFor="title">{t("contact:subject")}</label>
              <input
                type="text"
                name="title"
                onKeyPress={e => {
                  e.key === "Enter" && e.preventDefault()
                }}
                value={formData.title}
                onChange={onInputChange}
              ></input>
              <label htmlFor="message">{t("contact:message")}</label>
              <input
                type="text"
                name="message"
                onKeyPress={e => {
                  e.key === "Enter" && e.preventDefault()
                }}
                value={formData.message}
                onChange={onInputChange}
              ></input>
              <div className="position-btn">
                <button className="btn-white" type="submit">
                  {t("contact:send")}
                </button>
              </div>
              {isFormSubmitted && (
                <div className="position-btn">
                  <p>{t("contact:thank")}</p>
                </div>
              )}
            </form>
          </div>
          <div className="map-wrapper">
            <div>
              <Tabs onChange={onChange}>
                <TabPane tab={t("contact:belgrade")} key="bg">
                  <div className="tab-content-position box">
                    <img src="/img/phone.svg" alt="phone" />
                    <div>
                      <h6>{t("contact:phone")}</h6>
                      <p>{t("contact:office")}</p>
                      <a href="tel:+381 64 8446 654">
                        <p className="box-two">+381 64 8446 654</p>
                      </a>
                      <p>{t("contact:sale")}</p>
                      <a href="tel:+381 69 2662 970">
                        <p>+381 69 2662 970</p>
                      </a>
                    </div>
                  </div>

                  <div className="tab-content-position box">
                    <img src="/img/location.svg" alt="location" />
                    <div>
                      <h6>{t("contact:address")}</h6>
                      <p className="differente-style">Gorda doo</p>
                      <p>Obilićev venac 18, 11000 Beograd</p>
                    </div>
                  </div>

                  <div className="tab-content-position">
                    <img src="/img/mail.svg" alt="mail" />
                    <div>
                      <h6>E-MAIL</h6>
                      <a href="mailto:info@gorda.rs">
                        <p>info@gorda.rs</p>
                      </a>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Gornji Milanovac" key="gm">
                  <div className="tab-content-position box">
                    <img src="/img/phone.svg" alt="phone" />
                    <div>
                      <h6>{t("contact:phone")}</h6>
                      <a href="tel:+381 69 4511 463">
                        <p>+381 69 4511 463</p>
                      </a>
                    </div>
                  </div>

                  <div className="tab-content-position box">
                    <img src="/img/location.svg" alt="location" />
                    <div>
                      <h6>{t("contact:address")}</h6>
                      <p className="differente-style">Destilerija Gorda</p>
                      <p>Velereč bb, Gornji Milanovac</p>
                    </div>
                  </div>

                  <div className="tab-content-position">
                    <img src="/img/mail.svg" alt="mail" />
                    <div>
                      <h6>E-MAIL</h6>
                      <a href="mailto:info@gorda.rs">
                        <p>info@gorda.rs</p>
                      </a>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
            <div className="map">
              <GoogleMapReact
                center={mapCenter}
                defaultZoom={11}
                options={createMapOptions}
                bootstrapURLKeys={{
                  key: GOOGLE_API_KEY,
                }}
              >
                {mapCenter.lat === locations.bg.lat && (
                  <Marker
                    lat={locations.bg.lat}
                    lng={locations.bg.lng}
                    text="My Marker"
                  />
                )}
                {mapCenter.lat === locations.gm.lat && (
                  <Marker
                    lat={locations.gm.lat}
                    lng={locations.gm.lng}
                    text="My Marker"
                  />
                )}
              </GoogleMapReact>
            </div>
          </div>
          <div className="divider"></div>
        </div>
      </>
    </MainLayout>
  )
}

export default Contact
